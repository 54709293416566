import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/projects.css"

import ReactModal from "react-modal"

import Img from "gatsby-image"

ReactModal.setAppElement("body")

class Projects extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shownModal: -1,
    }
  }

  showModal(modalId) {
    this.setState({
      shownModal: modalId,
    })
  }

  closeModal() {
    this.setState({
      shownModal: -1,
    })
  }

  isModalShown(modalId) {
    return this.state.shownModal === modalId
  }

  render() {
    let count = 0
    let that = this
    return (
      <Layout>
        <SEO title="projects" />
        <StaticQuery
          query={graphql`
            {
              allMarkdownRemark(
                sort: { fields: [frontmatter___rating], order: DESC }
              ) {
                edges {
                  node {
                    html
                    frontmatter {
                      title
                      img {
                        childImageSharp {
                          fluid(maxWidth: 600, maxHeight: 250, quality: 100) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                      desc
                      link
                      rating
                    }
                  }
                }
              }
            }
          `}
          render={data => {
            return (
              <div className="projects">
                {data.allMarkdownRemark.edges.map(subData => {
                  let project = subData.node.frontmatter
                  let previousModal = null
                  let nextModal = null
                  if (count !== 0) {
                    previousModal = "project-" + (count - 1)
                  }
                  if (count !== data.allMarkdownRemark.edges.length - 1) {
                    nextModal = "project-" + (count + 1)
                  }
                  let modalId = "project-" + count++
                  return (
                    <div className="project" key={modalId}>
                      <a
                        style={{ textDecoration: "none" }}
                        //href={project.link}
                        onClick={() => {
                          //that.showModal(modalId)
                          var width = window.innerWidth * 0.66
                          // define the height in
                          var height =
                            (width * window.innerHeight) / window.innerWidth
                          window.open(
                            project.link,
                            "newwindow",
                            "width=" +
                              width +
                              ", height=" +
                              height +
                              ", top=" +
                              (window.innerHeight - height) / 2 +
                              ", left=" +
                              (window.innerWidth - width) / 2
                          )
                        }}
                      >
                        <Img
                          style={{ width: "100%" }}
                          fluid={project.img.childImageSharp.fluid}
                          alt={project.title}
                        />
                        <p class="bottom">
                          <span class="title">{project.title}</span>
                          <span class="desc"> - {project.desc}</span>
                        </p>
                      </a>
                      <ReactModal
                        style={{
                          overlay: {},
                          content: {
                            backgroundColor: "#262626",
                          },
                        }}
                        isOpen={that.isModalShown(modalId)}
                        onRequestClose={() => {
                          that.closeModal()
                        }}
                        contentLabel="Minimal Modal Example"
                      >
                        <button
                          onClick={() => {
                            that.closeModal()
                          }}
                          style={{ float: "left" }}
                        >
                          X
                        </button>
                        <h1 style={{ marginLeft: "4vw" }}>{project.title}</h1>
                        <h3 style={{ marginLeft: "4vw" }}>{project.desc}</h3>
                        <iframe
                          title={project.title}
                          src={project.link}
                          webkitallowfullscreen=""
                          mozallowfullscreen=""
                          allowFullScreen=""
                          style={{ height: "70vh", width: "90vw" }}
                          width="640"
                          height="360"
                          frameBorder="0"
                        />
                        {previousModal ? (
                          <button
                            style={{
                              position: "absolute",
                              left: "1vw",
                              bottom: "1vw",
                            }}
                            onClick={() => {
                              that.showModal(previousModal)
                            }}
                          >
                            {"<"}
                          </button>
                        ) : (
                          ""
                        )}
                        {nextModal ? (
                          <button
                            style={{
                              position: "absolute",
                              right: "1vw",
                              bottom: "1vw",
                            }}
                            onClick={() => {
                              that.showModal(nextModal)
                            }}
                          >
                            {">"}
                          </button>
                        ) : (
                          ""
                        )}
                      </ReactModal>
                    </div>
                  )
                })}
              </div>
            )
          }}
        />
      </Layout>
    )
  }
}

export default Projects
